const config = {

    // Local
    // api_base_url:"http://localhost:8888/QR_BackEnd/devapi/v8/",
    // api_base_url_v8:"http://localhost:8888/QR_BackEnd/devapi/v8/",

    // dev
    //api_base_url:"https://dev.quranrevolutionapp.com/devapi/v8/",
    //api_base_url_v8:"https://dev.quranrevolutionapp.com/devapi/v8/",

    // productions
    api_base_url:"https://sem.quranrevolutionapp.com/devapi/v8/",
    api_base_url_v8:"https://sem.quranrevolutionapp.com/devapi/v8/",


}

export default config;
