import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./nav-pill.css";
export class NavPill extends Component {
  render() {
    return (
      <React.Fragment>
        <li className="nav-item w-3">
          <a
            className={
              this.props.selected
                ? "nav-link shadow active "
                : "nav-link shadow "
            }
            data-toggle="tab"
            href="#"
            onClick={this.props.func}
          >
            {this.props.text}
          </a>
        </li>
      </React.Fragment>
    );
  }
}

export default NavPill;
