import {
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_LOGIN_FAILURE,
  API_SERVER_ERROR
} from "../actions/types";

export default function(state = { user: {} }, action) {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      var current_date = new Date();
      var creation_date = new Date(action.payload.user.creation_date);
      // One day Time in ms (milliseconds)
      var one_day = 1000 * 60 * 60 * 24;
      var days =
        Math.round(current_date.getTime() - creation_date.getTime()) / one_day;
      return {
        ...state,
        user: action.payload,
        login_error: null,
        server_error: null,
        user_days: days
      };
    case USER_LOGIN_FAILURE:
      return {
        ...state,
        server_error: null,
        login_error: action.payload.message,
        user_days: 0
      };

    case USER_LOGOUT:
      return {
        ...state,
        user: action.payload,
        login_error: null,
        server_error: null,
        user_days: 0
      };
    case API_SERVER_ERROR:
      return {
        user: "",
        login_error: null,
        server_error: action.payload,
        user_days: 0
      };

    default:
      return state;
  }
}
