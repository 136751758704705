import React, { Component } from "react";
export class VideoList extends Component {
  state = { loading: true };
  render() {
    return (
      <React.Fragment>
        <div className="col-md-3"></div>
        <div className="mt-5">
          <h4 className="mb-3 font-weight-bold"> {this.props.text}</h4>
          <div className=" embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              src={this.props.link}
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default VideoList;
