import React, { Component } from "react";
import logo from "../../images/logo.png";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./forget.css";
import { api_forgot } from "./../../api/api";

class ForgetPassword extends Component {
  state = {
    username: "",
    response: "msg_new"
  };

  displayOptions = {
    msg_new: <div></div>,
    msg_error: (
      <div className="alert alert-danger">
        <strong>Error!</strong> We were unable to reset your password
      </div>
    ),
    msg_success: (
      <div className="alert alert-success">
        <strong>Success!</strong> Kindly check your Email, Email was Sent
      </div>
    )
  };

  loginHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  responseHandler = response => {
    if (response.success === true) {
      this.setState({
        response: "msg_success"
      });
      // this.props.history.push("/");
    } else {
      this.setState({
        response: "msg_error"
      });
    }
  };

  reset_password_email = e => {
    api_forgot(this.state.username, this.responseHandler);
    e.preventDefault();
  };

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.reset_password_email(e);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="bg pt-5">
          <div
            className="container pb-5"
            style={{ color: "#8D8D8D", fontFamily: "Open Sans" }}
          >
            <div className="row margin">
              <div className="col-md-7"></div>
              <div className="col-md-5 logo-align">
                <div className="justify-content-center align-items-center">
                  <img src={logo} className="mx-auto d-block" />
                  <p className="text-center welcome-text">
                    Forgot Password ? Enter your username below
                  </p>
                </div>
                <form>
                  <div className="form-group input-field-align">
                    <div className="col-xs-3 text-center">
                      <input
                        style={{
                          height: 102,
                          borderRadius: "0.75rem",
                          border: "none"
                        }}
                        type="text"
                        className="form-control text-center font-weight-bold shadow"
                        placeholder="Username"
                        name="username"
                        value={this.state.username}
                        onChange={this.loginHandler.bind(this)}
                        onKeyDown={this.handleKeyDown.bind(this)}
                      />
                      <div>{this.displayOptions[this.state.response]}</div>
                      <input
                        className="btn btn-primary login-btn mt-3"
                        type="submit"
                        value={
                          this.state.response === "msg_success"
                            ? "Back"
                            : "Send"
                        }
                        onClick={
                          this.state.response === "msg_success"
                            ? () => {
                                this.props.history.push("/");
                              }
                            : this.reset_password_email
                        }
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <footer className="mastfoot mt-10">
          <div className="footer">
            <p>
              Copyright 2020 Quran Revolution. A Product of AlMaghrib Institute.
            </p>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.myState.user,
    error: state.myState.login_error
  };
};

export default connect(mapStateToProps)(withRouter(ForgetPassword));
