import React, { Component } from "react";
import LiveSessions from "./routes/live-sessions/live-sessions";
import Recording from "./routes/recording/Recording"
import Resources from "./routes/Resources/Resources"
import Lessons from "./routes/lessons/lessons";
import Login from "./routes/login/login";
import ForgetPassword from "./routes/forget/forget";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import { store, persistor } from "./redux/store/index";
import { PersistGate } from "redux-persist/integration/react";
import "bootstrap/dist/js/bootstrap.js";

class App extends Component {
  render() {
    return (
      <div>
        <Provider store={store}>
          <Router basename={process.env.PUBLIC_URL}>
            <Switch>
              <Route path="/live-sessions" component={LiveSessions} />
              <Route path="/recording" component={Recording} />
              <Route path="/resources" component={Resources} />
              <Route path="/lessons" component={Lessons} />
              <Route path="/forget-password" component={ForgetPassword} />
              <Route path="/" component={Login} />
              <Route component={Login} />
            </Switch>
          </Router>
        </Provider>
      </div>
    );
  }
}
persistor.subscribe(() => {
  /* Hydrate React components when persistor has synced with redux store */
  const { bootstrapped } = persistor.getState();
  if (bootstrapped) {
    ReactDOM.hydrate(<App />, document.getElementById("root"));
  }
});
export default App;

//{window.location.pathname}
//           <PersistGate loading={null} persistor={persistor}>
