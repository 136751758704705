import React, { Component } from "react";
// import Navbar from "./../../components/navbar/navBar";
import Navbar from "../../components/navbar/navbar";
import NavPill from "./../../components/common/nav-pill";
import Authentication from "./../../components/authentication/authenticatedComponent";
import RowLiveSession from "./../../components/liveSession/rowLiveSession";
import { api_Resources } from "../../api/api";
import { connect } from "react-redux";
import { serverError } from "../../redux/actions/userActions";

import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import "../live-sessions/live-sessions.css";

const tabs_text = [
  {
    title: "Upcoming",
    option_num: -2,
    index: 0,
  },
  {
    title: "Quran Reading Circle",
    option_num: 2,
    index: 1,
  },
  {
    title: "Live Sessions",
    option_num: 1,
    index: 2,
  },
  {
    title: "Office Hours",
    option_num: 3,
    index: 3,
  },
  {
    title: "Bonus Sessions",
    option_num: 4,
    index: 4,
  },
  {
    title: "Understand",
    option_num: 5,
    index: 5,
  },
];
class LiveSessions extends Component {
  state = {
    list_session: [],
    current_index: 0,
    session: {},
    isLoading: true,
    selected_index: this.props.currentUserLevel,
  };

  fetch_list = (index) => {
    const selected_option = tabs_text[index].option_num;
    this.setState({ list_session: [], current_index: index, isLoading: true });
    if (this.props.access_token) {
      api_Resources(
        this.props.access_token,
        selected_option,
        this.updated_list,
        this.props.serverError
      );
    } else this.props.history.push("/");
  };
  componentDidMount() {
    this.fetch_list(0);
  }

  updated_list = (items) => {
    this.setState({ list_session: items, isLoading: false });
  };

  map_to_row = (item) => {
    return (
      <RowLiveSession
        selected="active"
        text={item.res_title}
        link={item.file ? item.file : item.res_link}
        // link={item.file}
      />
    );
  };

  map_for_heading = (item) => {
    if (this.state.selected_index > 1 && item.index == 4) {
      return;
    } else {
      return item.index === this.state.current_index ? (
        <NavPill
          selected="active"
          text={item.title}
          func={() => this.fetch_list(item.index)}
        />
      ) : (
        <NavPill text={item.title} func={() => this.fetch_list(item.index)} />
      );
    }
  };
  update_data = () => {
    var list = this.state.isLoading ? (
      <div className="text-center">
        <Loader type="Puff" color="#8FC796" height={100} width={100} />
      </div>
    ) : (
      this.state.list_session.map(this.map_to_row)
    );
    return { list };
  };

  render() {
    const { list } = this.update_data();
    return (
      <React.Fragment>
        <Authentication />
        <Navbar />
        <div className="container col-md-12">
          <ul
            className="nav  nav-pills justify-content-center pt-4"
            role="tablist"
          >
          </ul>
          <div className="tab-content justify-content-center text-left ">
            <div id="link-1" className="container tab-pane active pt-4">

              {list}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  let level;
  if (state.myState.user && state.myState.user.user)
    level = state.myState.user.user.level;
  return {
    access_token: state.myState.user.access_token,
    currentUserLevel: level,
  };
};

export default connect(mapStateToProps, { serverError: serverError })(
  withRouter(LiveSessions)
);
