import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {serverError} from "../../redux/actions/userActions";

import "../lessons/lessons.css";
// import Navbar from "./../../components/navbar/navBar";
import Navbar from "../../components/navbar/navbar";
import NavPill from "./../../components/common/nav-pill";
import VideoList from "./../../components/videoList/videoList";
import Authentication from "./../../components/authentication/authenticatedComponent";
import Menu from "./../../components/menu/menu";

import {api_lessons} from "../../api/api";
import {api_recordings} from "../../api/api"
import moment from "moment";
import Loader from "react-loader-spinner";

const filters = {
    last_month: 'Last Month',
    last_week: 'Last Week',
    all: 'All',
}

class Recording extends Component {
    constructor(props) {
        super(props);
        let pickers = this.setPickerData();
        this.state = {
            selected_index: (this.props.currentUserLevel != 4 && this.props.currentUserLevel != 5) ? (this.props.currentUserLevel - 1) : ((this.props.currentUserLevel != 4) ? 3 : 4),
            selected_lesson: 0,
            lesson_level_map: {},
            selectedType: filters.last_month,
            selectedMonth: "Month",
            selectedYear: "Year",
            // monthList: moment.months().map((value, index) => {
            //         return {label: value, value: index.toString()};
            //     }),
            // yearList: [],
            pickers: pickers
        };

        this.fetchData();

    }

    setPickerData = () => {
        console.log("setPickerData")
        let pickers = [];
        pickers.push({
            key: 'all',
            value: filters.all,
            options: [
                {
                    label: filters.all,
                    value: filters.all
                },
                {
                    label: filters.last_week,
                    value: filters.last_week,
                },
                {
                    label: filters.last_month,
                    value: filters.last_month,
                },
            ],
            onSelected: value => {
                console.log(value);
                if (value === filters.all) {
                    this.setState({selectedType: value});
                } else {
                    this.setState({
                        selectedType: value,
                        selectedMonth: "Month",
                        selectedYear: "Year",
                    });
                }

            },
        });

        let monthsArr = moment.months().map((value, index) => {
            return {label: value, value: index.toString()};
        });
        monthsArr = [{label: "Month", value: "Month"}, ...monthsArr];
        pickers.push({
            key: 'month',
            placeHolder: 'Month',
            options: monthsArr,
            value: moment()
                .month()
                .toString(),
            onSelected: value => {
                if (value) {
                    this.setState({
                        selectedMonth: value,
                        selectedType: filters.all,
                    });
                } else {
                    this.setState({
                        selectedMonth: value,
                    });
                }
            },
        });

        var yearsArr = [];
        let year = moment().year();
        yearsArr.push({label: "Year", value: "Year"});

        for (let i = year; i > 2000; i--) {
            yearsArr.push({label: i.toString(), value: i.toString()});
        }

        pickers.push({
            key: 'year',
            placeHolder: 'Year',
            options: yearsArr,
            value: moment()
                .year()
                .toString(),
            onSelected: value => {
                if (value) {
                    this.setState({
                        selectedYear: value,
                        selectedType: filters.all,
                    });
                } else {
                    this.setState({
                        selectedYear: value,
                    });
                }
            },
        });

        return pickers;

        // let year = moment().year()
        // for (let i = year; i > 2000; i--) {
        //     this.state.yearList.push({label: i.toString(), value: i.toString()});
        // }
    }

    fetchData = () => {
        if (this.props.access_token) {

            var params = {};
            let month = this.state.selectedMonth;
            let year = this.state.selectedYear;
            let type = this.state.selectedType;

            if (month != "Month") {
                params.month = parseInt(month) + 1;
            }
            if (year != "Year") {
                params.year = year;
            }
            if (type === filters.last_week) {
                var dd = moment().subtract(7, 'days');
                var startDate = dd.startOf('week').format('YYYY-MM-DD');
                var endDate = dd.endOf('week').format('YYYY-MM-DD');
                params = {
                    startDate: startDate,
                    endDate: endDate,
                };
            } else if (type === filters.last_month) {
                var dd = moment().subtract(1, 'month');
                var startDate = dd.startOf('month').format('YYYY-MM-DD');
                var endDate = dd.endOf('month').format('YYYY-MM-DD');
                params = {
                    startDate: startDate,
                    endDate: endDate,
                };
            }

            api_recordings(
                this.props.access_token,
                (res) => {
                    console.log("api_recordings", res)
                    this.setState({
                        lesson_level_map: res,
                    });
                },
                this.props.serverError,


                params
            );
        } else this.props.history.push("/");
    }


    update_selected_level = (index) => {
        this.setState({selected_index: index});
    };

    update_selected_lesson = (id) => {
        this.setState({selected_lesson: id});
    };

    map_for_heading = (item) => {
        return item.index == this.state.selected_index ? (
            <NavPill
                selected="active"
                key={item.index}
                text={item.title}
                func={() => this.update_selected_level(item.index)}
            />
        ) : (
            <NavPill
                key={item.index}
                text={item.title}
                func={() => this.update_selected_level(item.index)}
            />
        );
    };
    map_for_videos = (item) => {
        // console.log("map_for_videos", item)
        let videoUrl = item.r_link
        if (videoUrl.includes('vimeo.com')) {
            var regExpression = /^[0-9]+$/i;
            let videoId = 363895177;
            let vimeoSplit = videoUrl.split('/');
            while (vimeoSplit.length > 0) {
                let last = vimeoSplit.pop();
                if (regExpression.test(last)) {
                    videoId = parseInt(last);
                    break;
                }
            }
            if (videoId) {
                videoUrl =
                    'https://player.vimeo.com/video/' +
                    videoId +
                    '?loop=1&title=0&byline=0&portrait=0';
            }
            console.log("videoUrl", item.r_title)

            return (
                <VideoList
                    key={videoUrl}
                    text={item.r_title}
                    link={videoUrl}
                />
            );
        }
        ;
    }

    update_data = () => {
        let videos_list;
        let lesson_menu;
        let level_options;
        if (this.state.lesson_level_map?.length > 0) {
            // console.log("lesson_level_map", this.state.lesson_level_map)
            lesson_menu = this.state.lesson_level_map.map(data => this.map_for_videos(data))
        } else {
            lesson_menu = <div>
                <h4 className="mb-3 font-weight-bold">No item in a list</h4>
            </div>
        }
        return {level_options, lesson_menu};
    };


    render() {
        const {level_options, lesson_menu} = this.update_data();
        console.log('lesson_menu', lesson_menu)
        return (
            <React.Fragment>
                <Authentication/>
                <Navbar/>

                <div className="container">
                    <div className="row  ">
                        <div className="col-md-12 col-sm-12">
                            <div className="mt-5">
                                <h4 className="mb-3 font-weight-bold"> Recording Sections</h4>
                                <ul
                                    className="nav  nav-pills justify-content-center "
                                    role="tablist"
                                >
                                    <select style={{padding: "10px", border: "1px solid black", margin: "10px"}}
                                            onChange={(event) => this.state.pickers[0].onSelected(event.target.value)}
                                            value={this.state.selectedType}>
                                        {this.state.pickers[0].options ? this.state.pickers[0].options.map(data =>
                                            <option value={data.value}>{data.label}</option>
                                        ) : ""}
                                    </select>

                                    <select style={{padding: "10px", border: "1px solid black", margin: "10px"}}
                                            onChange={(event) => this.state.pickers[1].onSelected(event.target.value)}
                                            value={this.state.selectedMonth}>
                                        {this.state.pickers[1].options ? this.state.pickers[1].options.map(data =>
                                            <option value={data.value}>{data.label}</option>
                                        ) : ""}
                                    </select>


                                    <select style={{padding: "10px", border: "1px solid black", margin: "10px"}}
                                            onChange={(event) => this.state.pickers[2].onSelected(event.target.value)}
                                            value={this.state.selectedYear}>
                                        {this.state.pickers[2].options ? this.state.pickers[2].options.map(data =>
                                            <option value={data.value}>{data.label}</option>
                                        ) : ""}
                                    </select>
                                    <div style={{paddingTop: "5px"}}>
                                        <NavPill
                                            selected="active"
                                            text="Apply"
                                            func={() => {
                                                this.fetchData()
                                            }}
                                        />
                                    </div>
                                </ul>

                                {/*<button*/}

                            </div>
                        </div>
                    </div>
                    {lesson_menu}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    let level;
    if (state.myState.user && state.myState.user.user)
        level = state.myState.user.user.level;
    return {
        access_token: state.myState.user.access_token,
        currentUserLevel: level,
        user_days: state.myState.user_days,
    };
};

export default connect(mapStateToProps, {serverError: serverError})(
    withRouter(Recording)
);
