import React, { Component } from "react";
import moment from 'moment';
import mommenttz from 'moment-timezone';
export class RowLiveSession extends Component {
  render() {
    //var testDateUtc = moment.utc();
    var date = this.props?.date;
    var prependFormattedDateTime ='';
    if(date)
    {
      var datetimeWithTimeZone = mommenttz.tz(this.props.date+" "+this.props.time,this.props.timezone);
      var localDate = moment(datetimeWithTimeZone).local();
      var formattedDateTime = localDate.format("dddd MMM, Do hh:mm a ");
      var prependFormattedDateTime = " | " + formattedDateTime;

    }
    
    return (
      <React.Fragment>
        <a
            onClick={() => this.props.link ? window.open(this.props.link, "_blank") : alert("Invalid Parameter!")}
          // href={this.props.link}
          className={
            this.props.selected
              ? "btn btn-30 mt-4 font-weight-bold"
              : "btn btn-30 mt-4 font-weight-bold shadow"
          }
          // target="_blank"
          rel="noreferrer noopener"
        >
          {this.props.text}{prependFormattedDateTime}
        </a>
      </React.Fragment>
    );
  }
}

export default RowLiveSession;
