import React, { Component } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import "bootstrap/dist/css/bootstrap.css";
import "./paginator.css";

class Paginator extends Component {
  styling = {
    styles: "counting justify-content-between align-item-center text-center",
  };

  page_control = () => {
    let backButton, nextButton;
    if (this.props.current_page < this.props.total_pages) {
      nextButton = (
        <a
          className="btn float-right  next-btn "
          href="#"
          role="button"
          onClick={() => {
            this.props.callback(this.props.current_page + 1);
          }}
        >
          Next <IoIosArrowForward />
        </a>
      );
    }

    if (this.props.current_page > 1) {
      backButton = (
        <a
          className="float-left next-btn "
          href="#"
          role="button"
          onClick={() => this.props.callback(this.props.current_page - 1)}
        >
          <IoIosArrowBack /> Pre
        </a>
      );
    }

    return { backButton, nextButton };
  };

  render() {
    const { backButton, nextButton } = this.page_control();
    return (
      <div>
        <div className={this.styling.styles}>
          {backButton}

          <span style={{ lineHeight: 3, height: 3 }}>
            <span>{this.props.current_page} </span>
            <span>of</span>
            <span> {this.props.total_pages}</span>
          </span>
          {nextButton}
        </div>
      </div>
    );
  }
}

export default Paginator;
