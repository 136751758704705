import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class AuthenticatedComponent extends Component {
  static propTypes = {
    // user: PropTypes.object
  };

  componentDidMount() {
    this._checkAndRedirect();
  }

  componentDidUpdate() {
    this._checkAndRedirect();
  }

  _checkAndRedirect() {
    const { user, server_error } = this.props;
    if (!user.access_token || server_error) {
      this.props.history.push("/");
    }
  }

  render() {
    return <div className="authenticated"></div>;
  }
}

const mapStateToProps = state => {
  return {
    user: state.myState.user,
    server_error: state.myState.server_error
  };
};

export default connect(mapStateToProps)(withRouter(AuthenticatedComponent));
