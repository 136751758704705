import React, { Component } from "react";
import Row from "./rows/row";
import Paginator from "./paginator/paginator";

export class Menu extends Component {
  state = {
    current_page: 1,
    total_pages: 0,
    selected_lesson_id: 0,
    total_lessons: 0,
  };

  update_list = () => {
    if (this.props.levelMap) {
      if (
        this.state.total_pages !==
          Math.ceil(this.props.level == 'understand' ? this.props.levelMap['understand'].length / 8 : this.props.levelMap[this.props.level + 1].length / 8) ||
        this.state.total_lessons !==
          (this.props.level == 'understand' ? this.props.levelMap['understand']?.length : this.props.levelMap[this.props.level + 1].length)
      ) {
        this.setState({
          current_page: 1,
          selected_lesson_id: (this.props.level == 'understand' ? this.props.levelMap['understand'][0] : this.props.levelMap[this.props.level + 1][0])
            .ls_id,
          total_pages: Math.ceil(
              this.props.level == 'understand' ? this.props.levelMap['understand'].length / 8 : this.props.levelMap[this.props.level + 1].length / 8
          ),
          total_lessons: this.props.level == 'understand' ? this.props.levelMap['understand'].length : this.props.levelMap[this.props.level + 1].length,
        });
        this.props.callback(this.props.level == 'understand' ? this.props.levelMap['understand'][0].ls_id : this.props.levelMap[this.props.level + 1][0].ls_id);
      }
    }
  };

  map_for_rows = (item) => {
    return item.ls_id == this.state.selected_lesson_id ? (
      <li className="nav-item items " key={item.ls_id}>
        <Row
          selected={
            this.props.user_days >= item.ls_posted_days ? "active" : "locked"
          }
          text={item.ls_videos[0].ls_title}
          func={() => {
            this.setState({ selected_lesson_id: item.ls_id });
            this.props.callback(item.ls_id);
          }}
        />
      </li>
    ) : (
      <li className="nav-item items " key={item.ls_id}>
        <Row
          selected={
            this.props.user_days >= item.ls_posted_days ? "deactive" : "locked"
          }
          text={item.ls_videos[0].ls_title}
          func={() => {
            this.setState({ selected_lesson_id: item.ls_id });
            this.props.callback(item.ls_id);
          }}
        />
      </li>
    );
  };

  page_change_method = (newPage) => this.setState({ current_page: newPage });

  componentWillMount() {
    this.update_list();
  }

  componentDidUpdate() {
    this.update_list();
  }

  render() {
    let lessons;
    if (this.props.levelMap[this.props.level + 1]) {
      if (this.state.current_page * 8 - 1 < this.state.total_lessons)
        lessons = this.props.levelMap[this.props.level + 1]
          .slice((this.state.current_page - 1) * 8, this.state.current_page * 8)
          .map(this.map_for_rows);
      else
        lessons = this.props.levelMap[this.props.level + 1]
          .slice((this.state.current_page - 1) * 8, this.state.total_lessons)
          .map(this.map_for_rows);
    }

    let understand;
    if(this.props.level == 'understand' && this.props.levelMap['understand'])
      understand = this.props.levelMap['understand']
          .slice((this.state.current_page - 1) * 8, this.state.total_lessons)
          .map(this.map_for_rows);
    return (
      <ul className="values nav nav-pills" aria-orientation="vertical">
        {lessons}
        {this.props.level == 'understand' && understand}
        <li className="nav-item items">
          <Paginator
            current_page={this.state.current_page}
            total_pages={this.state.total_pages}
            callback={this.page_change_method}
          />
        </li>
      </ul>
    );
  }
}

export default Menu;
