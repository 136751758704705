import {
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_LOGIN_FAILURE,
  API_SERVER_ERROR
} from "./types";
import { api_login } from "../../api/api";

export const serverError = error => dispatch => {
  dispatch({ type: API_SERVER_ERROR, payload: error });
};

export const fetchUser = (username, password) => dispatch => {
  api_login(
    username,
    password,
    
    response => {
      if (response.access_token) {
       
        
        dispatch({ type: USER_LOGIN_SUCCESS, payload: response });
      } else {
       
        dispatch({ type: USER_LOGIN_FAILURE, payload: response });
      }
    },
    response => {
      serverError(response);
    },
    
  );
};

export const logoutUser = () => dispatch => {
  dispatch({ type: USER_LOGOUT, payload: { user: "", error_login: "" } });
};
