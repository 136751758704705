import React, { Component } from "react";
import logo from "../../images/logo.png";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../login/login.css";
import { fetchUser } from "../../redux/actions/userActions";

export class Login extends Component {
  state = {
    username: "",
    password: "",
  };
  loginHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.user.access_token !== this.props.user.access_token) {
      this.props.history.push("/lessons");
    }
    return true;
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.props.fetchUser(this.state.username, this.state.password);
    }
  };
  handleLogin = (e) => {
    e.preventDefault();
    this.props.fetchUser(this.state.username, this.state.password);
  };

  render() {
    return (
      <React.Fragment>
        <div className="bg pt-5">
          <div
            className="container pb-5"
            style={{ color: "#8D8D8D", fontFamily: "Open Sans" }}
          >
            <div className="row margin">
              <div className="col-md-7"></div>
              <div className="col-md-5 logo-align">
                <div className="justify-content-center align-items-center">
                  <img src={logo} className="mx-auto d-block" />
                  <p className="text-center welcome-text">
                    Welcome to Quran Revolution! Enter your <br /> username and
                    password below.
                  </p>
                </div>
                <form>
                  <div className="form-group input-field-align">
                    <div className="col-xs-3">
                      <input
                        type="text"
                        className="form-control text-center font-weight-bold shadow name-field"
                        placeholder="Username"
                        name="username"
                        value={this.state.username}
                        onChange={this.loginHandler.bind(this)}
                      />
                    </div>
                    <div className="col-xs-3 password-field-align">
                      <input
                        type="password"
                        className="form-control text-center font-weight-bold shadow btn-style pwd-field"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.loginHandler.bind(this)}
                        onKeyDown={this.handleKeyDown}
                        name="password"
                      />
                    </div>
                    <div
                      className={
                        typeof this.props.error === "string"
                          ? "alert alert-danger"
                          : ""
                      }
                    >
                      {typeof this.props.error === "string"
                        ? this.props.error
                        : ""}
                    </div>

                    <div className="text-center pt-4">
                      <input
                        className="btn btn-primary login-btn"
                        type="submit"
                        value="Login"
                        onClick={this.handleLogin}
                      />
                    </div>
                    <div className="text-center pt-3">
                      <a
                        href="#"
                        className="text-center font-weight-bold forgot"
                        onClick={() => {
                          this.props.history.push("/forget-password");
                        }}
                      >
                        Forgot Password?
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <footer className="mastfoot mt-10">
          <div className="footer">
            <p>
              Copyright 2020 Quran Revolution. A Product of AlMaghrib Institute.
            </p>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.myState.user,
    error: state.myState.login_error,
  };
};

export default connect(mapStateToProps, { fetchUser: fetchUser })(
  withRouter(Login)
);
