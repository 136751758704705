// const BASE_URL = "http://3.17.24.185/qr_semester/devapi/v7/";
// const BASE_URL = "http://dev.quranrevolutionapp.com/devapi/v7/";
// const BASE_URL = "https://sem.quranrevolutionapp.com/devapi/v7/";
// const BASE_URL_V8 = "https://sem.quranrevolutionapp.com/devapi/v8/";
// const BASE_URL = "http://quranrevolutionapp.com/devapi/v7/";


// const BASE_URL = "http://localhost:8888/QR_BackEnd/devapi/v8/";
// const BASE_URL_V8 = "http://localhost:8888/QR_BackEnd/devapi/v8/";

// for dev
// const BASE_URL = "https://dev.quranrevolutionapp.com/devapi/v8/";
// const BASE_URL_V8 = "https://dev.quranrevolutionapp.com/devapi/v8/";

// for production
// const BASE_URL = "https://sem.quranrevolutionapp.com/devapi/v8/";
// const BASE_URL_V8 = "https://sem.quranrevolutionapp.com/devapi/v8/";

import config from "./Config";

const BASE_URL = config.api_base_url;
const BASE_URL_V8 = config.api_base_url_v8;


const headers = {'App-Platform': 'web','App-Version' : '1.0.0'}

// const platform = '&platform=web'
const platform = "&platform=web&app-version=1.0.0"

export const api_login = async (
  username,
  password,
  callback,
  failureCallback
) => {
  // console.log("API_LOGIN");
  try {
    console.log('Response ',)
    const response = await fetch(BASE_URL + "users/login?v=1"+platform, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers
      },
      body: JSON.stringify({
        username: username,
        password: password
      }),
    });

    if (response.status !== 200 && response.status !== 404 ) {
      failureCallback("Failed to get successfull response");
    } else {
      const user = await response.json();
      callback(user);
    }
    failureCallback("Failed to get successfull response");
    // callback(user.access_token ? user : user);
  } catch (err) {
    return callback(err.message);
  }
};

export const api_forgot = async (username, callback, failureCallback) => {
  // console.log("API_FORGET");
  try {
    const params = {
      headers: {
        "Content-Type": "application/json",...headers
      },
      method: "POST",
      body: JSON.stringify({
        email: username
      })
    };
    const response = await fetch(BASE_URL + "users/resetpassword?v=1"+platform, params);
    if (response.status !== 200) {
      failureCallback("Failed to get successfull response");
    } else {
      const user = await response.json();
      callback(user);
    }
  } catch (err) {
    return callback(err.message);
  }
};

export const api_live_sessions = async (
  access_token,
  lvs_category,
  callback,
  failureCallback
) => {
  try {
    const URL =
      BASE_URL +
      "live-sessions/get-live-sessions?v=1"+platform+"&access_token=" +
      access_token;
    const response = await fetch(URL, {
      headers: {
        "Content-Type": "application/json",...headers
      },
      method: "POST",
      body: JSON.stringify({ lvs_category: lvs_category })
    });

    if (response.status !== 200) {
      failureCallback("Failed to get successfull response");
    } else {
      const results = await response.json();
      callback(results.items);
    }
  } catch (err) {
    failureCallback(err.message);
  }
};

export const api_lessons = async (access_token, callback, failureCallback) => {
  try {
    const URL =
      BASE_URL + "lessons/get-lessons-web?v=1"+platform+"&access_token=" + access_token;
    const response = await fetch(URL,{
      headers: {
        "Content-Type": "application/json",...headers
      },
    });
    if (response.status != 200) {
      failureCallback("Failed to get successfull response");
    } else {
      const results = await response.json();
      callback(results);
    }

  } catch (err) {
    failureCallback(err.message);
  }
};

export const api_recordings = async (access_token, callback, failureCallback, data) => {
  try {
    const URL =
        BASE_URL_V8 +
        "recordings/get-recordings?access_token=" +
        access_token + "&page=1&v=1"+platform;
    const response = await fetch(URL, {
      headers: {
        "Content-Type": "application/json",...headers
      },
      method: "POST",
      body: JSON.stringify(data)
    });

    if (response.status !== 200) {
      failureCallback("Failed to get successfull response");
    } else {
      const results = await response.json();
      callback(results.items);
    }
  } catch (err) {
    failureCallback(err.message);
  }
};

// export const api_Resources = async (access_token, callback, failureCallback, data) => {
//   try {
//     const URL =
//         BASE_URL_V8 +
//     "resources/get-resources?access_token=" +
//         access_token + "&page=1&v=1";
//     const response = await fetch(URL, {
//       headers: {
//         "Content-Type": "application/json"
//       },
//       method: "POST",
//       body: JSON.stringify(data)
//     });
//
//     if (response.status !== 200) {
//       failureCallback("Failed to get successfull response");
//     } else {
//       const results = await response.json();
//       callback(results.items);
//       console.log("results **********************",results)
//     }
//   } catch (err) {
//     failureCallback(err.message);
//   }
// };


// export const api_Resources = async (access_token, callback, failureCallback) => {
//   console.log('updated_list **************** callback',)
//   try {
//     const URL =
//         BASE_URL_V8 + "resources/get-resources?access_token=" + access_token + "&page=1&v=1";
//     const response = await fetch(URL);
//     if (response.status != 200) {
//       failureCallback("Failed to get successfull response");
//     } else {
//       const results = await response.json();
//       callback(results);
//       console.log('updated_list **************** callback',results)
//     }
//
//   } catch (err) {
//     failureCallback(err.message);
//   }
// };


export const api_Resources = async (
    access_token,
    lvs_category,
    callback,
    failureCallback
) => {
  // console.log("API_LIVE_SESSION");
  try {
    const URL =  BASE_URL_V8 + "resources/get-resources?access_token=" + access_token + "&page=1&v=1"+platform;

    const response = await fetch(URL, {
      headers: {
        "Content-Type": "application/json",...headers
      },
      method: "POST",
      body: JSON.stringify({ lvs_category: lvs_category })
    });

    if (response.status !== 200) {
      failureCallback("Failed to get successfull response");
    } else {
      const results = await response.json();
      callback(results.items);
    }
  } catch (err) {
    failureCallback(err.message);
  }
};
