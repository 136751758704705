import React, { Component } from "react";
import user from "../../images/user.png";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/userActions";
import "./navbar.css";
// import "./navbar.css";
import { NavLink } from "react-router-dom";
export class Navbar extends Component {
  moveToLiveSession = e => {
    e.preventDefault();
    this.props.history.push("/live-links");
  };
  moveToLesson = e => {
    e.preventDefault();
    this.props.history.push("/videos");
  };

  _logoutUser = e => {
    e.preventDefault();
    this.props.history.push("/");
    this.props.logoutUser();
  };

  render() {
    return (
      <React.Fragment>
        <nav className="navbar navbar-light navbar-expand-md bg-faded justify-content-center px-md-5">
          <a
            href="#"
            className="navbar-brand d-flex w-75 mr-auto font-weight-bold"
          >
            QURAN REVOLUTION
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#collapsingNavbar3"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="navbar-collapse collapse w-100"
            id="collapsingNavbar3"
          >
            <ul className="navbar-nav w-100  justify-content-left">
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  to="/lessons"
                  className="nav-link"
                >
                  LESSONS
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  to="/live-sessions"
                  className="nav-link"
                >
                  Live Sessions
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  to="/recording"
                  className="nav-link"
                >
                  Recording
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  to="/resources"
                  className="nav-link"
                >
                  Resources
                </NavLink>
              </li>
            </ul>

            <ul className="navbar-nav justify-content-right ">
              <li className="nav-item dropdown align-profile">
                <a
                  className="dropdown-item mbl-logout-btn"
                  href="#"
                  onClick={this._logoutUser}
                >
                  Logout
                </a>

                <div
                  className="nav-link dropdown-toggle drop-btn"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src={user}
                    width="40"
                    height="40"
                    className="rounded-circle image"
                  />
                </div>

                <div className="btn-group">
                  <div
                    className="dropdown-menu dropdown-menu-right move-right  "
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <a
                      className="dropdown-item logout-btn"
                      href="#"
                      onClick={this._logoutUser}
                    >
                      Logout
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.myState.user
  };
};

export default connect(mapStateToProps, { logoutUser: logoutUser })(
  withRouter(Navbar)
);
