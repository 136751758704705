import React, { Component } from "react";
import lock from "../../../images/lock.png";
export class Row extends Component {
  styling = {
    locked: {
      className: "nav-link gray-btn shadow disabled",
      image: (
        <span className="lock">
          <img className="lock" src={lock} />{" "}
        </span>
      ),
      onClickFunc: () => {},
    },
    active: {
      className: "nav-link shadow active green-btn",
      image: "",
      onClickFunc: () => this.props.func(),
    },
    deactive: {
      className: "nav-link white-btn shadow",
      image: "",
      onClickFunc: () => this.props.func(),
    },
  };

  render() {
    let myStyle = this.styling[this.props.selected];
    return (
      <div>
        <a
          className={myStyle.className}
          data-toggle="tab"
          href="#"
          onClick={myStyle.onClickFunc}
        >
          {myStyle.image}
          {this.props.text}
        </a>
      </div>
    );
  }
}

export default Row;
