import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {serverError} from "../../redux/actions/userActions";

import "./lessons.css";
// import Navbar from "./../../components/navbar/navBar";
import Navbar from "../../components/navbar/navbar";
import NavPill from "./../../components/common/nav-pill";
import VideoList from "./../../components/videoList/videoList";
import Authentication from "./../../components/authentication/authenticatedComponent";
import Menu from "./../../components/menu/menu";

import {api_lessons} from "../../api/api";

import Loader from "react-loader-spinner";

//  LEVELS
// 0 1 2   3       4
// 1 2 3 Year 2    4
const level_menu_headings = [
    {
        title: "Level 1 Lessons",
        index: 0,
        level: 1,
    },
    {
        title: "Level 2 Lessons",
        index: 1,
        level: 2,
    },
    {
        title: "Level 3 Lessons",
        index: 2,
        level: 3,
    },
    {
        title: "Level 4 Lessons",
        index: 3,
        level: 5,
    },
    {
        title: "Year 2 Lessons",
        index: 4,
        level: 4,
    },
    {
        title: "Understand",
        index: 5,
        level: 'understand',
    },


];

class Lessons extends Component {
    state = {
        selected_index: (this.props.currentUserLevel != 4 && this.props.currentUserLevel != 5) ? (this.props.currentUserLevel - 1) : ((this.props.currentUserLevel != 4) ? 3 : 4),
        selected_lesson: 0,
        lesson_level_map: {},
    };

    componentDidMount() {
        if (this.props.access_token) {
            api_lessons(
                this.props.access_token,
                (res) => {
                    let mySelectedIndex = (this.props.currentUserLevel != 4 && this.props.currentUserLevel != 5) ? (this.props.currentUserLevel - 1) : ((this.props.currentUserLevel != 4) ? 3 : 4);
                    console.log('response', res)
                    this.setState({
                        lesson_level_map: res,
                        selected_index: mySelectedIndex,
                    });
                },
                this.props.serverError
            );
        } else this.props.history.push("/");
    }

    update_selected_level = (index) => {
        this.setState({selected_index: index});
    };

    update_selected_lesson = (id) => {
        this.setState({selected_lesson: id});
    };

    map_for_heading = (item) => {
        return item.index == this.state.selected_index ? (
            <NavPill
                selected="active"
                key={item.index}
                text={item.title}
                func={() => this.update_selected_level(item.index)}
            />
        ) : (
            <NavPill
                key={item.index}
                text={item.title}
                func={() => this.update_selected_level(item.index)}
            />
        );
    };
    map_for_videos = (item) => {
        return (
            <VideoList
                key={item.private_link}
                text={item.ls_title}
                link={item.private_link}
            />
        );
    };

    update_data = () => {
        let videos_list;
        let lesson_menu;
        let level_options;
        if (this.state.selected_index >= 0) {
            if (
                this.state.lesson_level_map[
                    this.state.selected_index == 5 ? level_menu_headings[this.state.selected_index].level :
                        level_menu_headings[this.state.selected_index].level + 1
                    ]
            ) {
                if (
                    this.state.lesson_level_map[
                        this.state.selected_index == 5 ? level_menu_headings[this.state.selected_index].level :
                            level_menu_headings[this.state.selected_index].level + 1
                        ].filter((item) => item.ls_id === this.state.selected_lesson).length
                )

                    videos_list = this.state.lesson_level_map[
                        (this.state.selected_index == 5 ? level_menu_headings[this.state.selected_index].level :
                            level_menu_headings[this.state.selected_index].level + 1)
                        ]
                        .filter((item) => item.ls_id === this.state.selected_lesson)[0]
                        .ls_videos.map(this.map_for_videos);
            }
            lesson_menu = this.state.lesson_level_map[2] ? (
                <div className="row ">
                    <div className="col-md-8">
                        <div className="video-content mb-5 bold ">{videos_list}</div>
                    </div>
                    <div className="col-md-4 lesson-menu">
                        <div className="" role="tablist">
                            <Menu
                                levelMap={this.state.lesson_level_map}
                                level={level_menu_headings[this.state.selected_index].level}
                                user_days={
                                    this.props.currentUserLevel ==
                                    level_menu_headings[this.state.selected_index].level
                                        ? this.props.user_days
                                        : 365
                                }
                                callback={this.update_selected_lesson}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="text-center">
                    <Loader type="Puff" color="#8FC796" height={100} width={100}/>
                </div>
            );
            level_options = level_menu_headings
                .filter((item) => {
                        if (this.state.lesson_level_map['understand'] && item.level == 'understand') {
                            return true;
                        }
                        if (this.props.currentUserLevel == 4) {
                            return true;
                        } else if (item.level != 4) {
                            return item.level <= this.props.currentUserLevel;
                        } else if (item.level == this.props.currentUserLevel) {
                            return true;
                        }

                    }
                )
                .map(this.map_for_heading)
                .reverse();
        } else {
            level_options = (
                <h3 className="mt-4">
                    Your level is 0 and there are no lessons for this level
                </h3>
            );
        }

        if (level_options[0] !== undefined && level_options[0] && level_options[0].key == 5) {
            let value = [...level_options.slice(1), level_options[0]]
            level_options = value
        }
        return {level_options, lesson_menu};
    };

    render() {
        const {level_options, lesson_menu} = this.update_data();
        return (
            <React.Fragment>
                <Authentication/>
                <Navbar/>
                <div className="container">
                    <div className="row  ">
                        <div className="col-md-12 col-sm-12">
                            <div>
                                <ul
                                    className="nav  nav-pills justify-content-center  pt-4 mb-3"
                                    role="tablist"
                                >
                                    {level_options}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {lesson_menu}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    let level;
    if (state.myState.user && state.myState.user.user)
        level = state.myState.user.user.level;
    return {
        access_token: state.myState.user.access_token,
        currentUserLevel: level,
        user_days: state.myState.user_days,
    };
};

export default connect(mapStateToProps, {serverError: serverError})(
    withRouter(Lessons)
);
